@import "../Globals.scss";

.Service {
  padding: 40px 240px 80px;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }

  .Service_body {
    display: flex;
    justify-content: space-between;

    .Left_side {
      display: flex;
      flex-direction: column;
      gap: 60px;
      max-width: 50%;
    }

    .Right_side {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      max-width: 50%;
      .Plans_Wrapper {
        width: 50%;
        border: 1px solid #e5e5e5;

        .btns {
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          .btn {
            background: none;
            border: none;
            border: 0.1px solid #e5e5e5;
            padding: 10px;
          }
        }

        .package_skeleton {
          padding: 30px 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .Price {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
          }

          .description {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            span {
              font-weight: 700;
            }
          }

          .more_info {
            div {
              display: flex;
              gap: 4px;
              margin-bottom: 4px;
            }

            .delivery {
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
            }

            .session {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              /* identical to box height, or 133% */
              color: #aeb0b3;
            }
          }

          .continue {
            padding: 10px;
            border: none;
            background: none;
            background-color: $primary__color;
            color: $white__color;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }

      .Contact_seller_btn {
        button {
          padding: 10px 140px;
          background: none;
          border: 1px solid $light__gray__color;
        }
      }
    }
  }
}
