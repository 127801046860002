@import '../Globals.scss';

.HappyStart {
  padding: 0 16rem 8rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  background-color: $white__color;
  margin: 8rem;
  overflow: hidden;

  h1 {
    margin-bottom: -1.4rem;
    text-align: center;
  }

  p {
    margin-bottom: -1.4rem;
    text-align: center;
  }
}

@media (max-width: 900px) {
  .HappyStart {
    border-top: 2px solid $light__primary__color;
    margin: 0px;
    padding: 0 8rem 4rem;
  }
}
