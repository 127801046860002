@import '../Globals.scss';

.Benefits {
  padding: 2rem 24rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8rem;
  margin-top: 18rem;
  background-color: white;
  position: relative;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }

  .global__symbol {
    height: 10rem;
    width: 10rem;
    position: absolute;
    top: -6rem;
  }

  .start__changing {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    padding: 2rem;

    .big_Title,
    .mini_Title {
      text-align: center;
    }
    .big_Title {
      margin-top: 3rem;
    }
    .mini_Title {
      margin-top: 1rem;
      margin-bottom: 3rem;
    }

    .differences__container {
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: space-between;

      .differnce {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        padding: 2rem;

        img {
          width: 6rem;
          margin-bottom: 0.8rem;
        }
        h2 {
          text-align: center;
          margin-bottom: 0.8rem;
        }

        p {
          text-align: center;
        }
      }
    }
  }

  .focus__on__help {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    .searchIcon {
      width: 8rem;
      margin-bottom: 0.6rem;
    }

    .help__options__container {
      margin-top: 3.2rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 8rem;
      align-items: center;
      justify-content: center;

      .help__option {
        max-width: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 6rem;
          margin-bottom: 0.8rem;
        }

        h3 {
          font-weight: 600;
          margin-bottom: 0.6rem;
        }
        h3,
        p {
          text-align: center;
        }
      }
    }
  }

  .request__a__coach {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    align-items: center;

    img {
      width: 8rem;
    }

    p {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1800px) {
  .Benefits {
    padding: 2rem 14rem 8rem;
  }
}

@media (max-width: 900px) {
  .Benefits {
    padding: 2rem 4rem 8rem;
    margin: 12rem 0;

    .start__changing {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      padding: 2rem;
      overflow: hidden;

      .big_Title,
      .mini_Title {
        text-align: center;
      }
      .big_Title {
        margin-top: 3rem;
      }
      .mini_Title {
        margin-top: 1rem;
        margin-bottom: 3rem;
      }

      .differences__container {
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: space-between;

        .differnce {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 25%;
          padding: 2rem;

          img {
            width: 6rem;
            margin-bottom: 0.8rem;
          }
          h2 {
            text-align: center;
            margin-bottom: 0.8rem;
          }

          p {
            text-align: center;
          }
        }
      }
    }

    .focus__on__help {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;

      .searchIcon {
        width: 8rem;
        margin-bottom: 0.6rem;
      }

      .help__options__container {
        margin-top: 3.2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 8rem;
        align-items: center;
        justify-content: center;

        .help__option {
          max-width: 40rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 6rem;
            margin-bottom: 0.8rem;
          }

          h3 {
            font-weight: 600;
            margin-bottom: 0.6rem;
          }
          h3,
          p {
            text-align: center;
          }
        }
      }
    }

    .request__a__coach {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem;
      align-items: center;

      img {
        width: 8rem;
      }

      p {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  .Benefits {
    padding: 2rem 4rem 8rem;
    margin: 12rem 0;

    .start__changing {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      padding: 2rem;
      overflow: hidden;

      .differences__container {
        gap: 1rem;

        .differnce {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1rem;

          img {
            width: 4.2rem;
          }
          h2 {
            font-size: 1.4rem;
          }

          p {
            font-size: 1rem;
            line-height: 1.2rem;
          }
        }
      }
    }

    .focus__on__help {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;

      .searchIcon {
        width: 4.2rem;
        margin-bottom: 0.6rem;
      }

      .help__options__container {
        margin-top: 3.2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 8rem;
        align-items: center;
        justify-content: center;

        .help__option {
          max-width: 40rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 4.2rem;
            margin-bottom: 0.8rem;
          }

          h3 {
            font-weight: 600;
            margin-bottom: 0.6rem;
          }
          h3,
          p {
            text-align: center;
          }
        }
      }
    }

    .request__a__coach {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem;
      align-items: center;

      img {
        width: 4.2rem;
      }

      p {
        margin-bottom: 10px;
      }
    }
  }
}
