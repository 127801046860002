@import '../Globals.scss';

.CoachProfile {
  padding: 3rem 6rem;
  background-color: $very__light__blue;
  display: flex;
  flex-direction: row;
  gap: 40px;

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: 70%;

    h1 {
      font-size: 19px;
      line-height: 29px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }

    .SummaryBox {
      background-color: $white__color;
      position: relative;
      display: flex;
      gap: 20px;

      .EditIcon {
        margin-left: 95%;
        margin-top: 1%;
        position: absolute;
        border-radius: 50%;
      }

      .EditIcon:hover {
        cursor: pointer;
        background-color: #d3d3d3;
      }

      .rating {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: flex-end;
        margin-bottom: 10px;
      }

      .languageContainer {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
      }

      .text-wrapper {
        padding: 10px;
        .title {
          display: flex;
          gap: 20px;

          h2 {
            font-size: 20px;
            line-height: 29px;
          }
        }
        .coach__type {
          font-size: 16px;
          line-height: 23px;
          color: $light__gray__color;
          margin-bottom: 10px;
        }

        .native {
          background-color: $green__secondary__color;
          color: $green__darker__color;
          padding: 5px;
          // padding-right: 5px;
        }
      }
    }

    .mini-navbar {
      background-color: $white__color;
      padding: 10px 20px;
      border-radius: $border__radius;
      position: -webkit-sticky;
      position: sticky;
      top: 9rem;

      ul {
        list-style: none;
        display: flex;
        gap: 20px;

        a {
          text-decoration: none;
          font-size: 14px;
          line-height: 20px;
          color: $black__color;
          font-weight: bold;
          transition: all 0.4s;
        }

        a:hover {
          color: $primary__color;
        }
      }
    }

    .About {
      background-color: $white__color;
      padding: 20px 40px;
      border-radius: $border__radius;
      scroll-margin-top: 14rem;

      h1 {
        font-weight: bold;
      }

      .education {
        .educationInfo {
          font-size: 14px;
          font-weight: bold;
        }

        .educationOrganisation {
          font-size: 12px;
          font-weight: lighter;
        }
      }

      .linkedAccounts {
        .linkedAccount {
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
        }
      }

      .language {
        .languageName {
          font-size: 14px;
        }

        .languageLevel {
          font-size: 14px;
        }
      }
    }

    .Schedule {
      background-color: $white__color;
      padding: 20px 40px;
    }

    .Resume {
      background-color: $white__color;
      padding: 20px 40px;
    }

    .Languages {
      background-color: $white__color;
      padding: 20px 40px;
    }

    .skills {
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }
  }

  .right__sidebar {
    max-height: fit-content;
    width: 30%;

    .image__wrapper {
      width: 100%;
      height: 240px;
      background-image: url(../../assets/coachProfile/woman1.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      margin-bottom: 1rem;
    }

    .quickBox {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 20px 20px;
      background-color: $white__color;
      margin-bottom: 20px;

      .info {
        padding: 20px 0 10px;
        display: flex;
        gap: 60px;
        justify-content: center;

        .smallInfo {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 5px 30px;
          background-color: $green__secondary__color;
          color: $green__darker__color;
          font-size: 14px;
          line-height: 20px;
        }
        .wage {
          font-size: 19px;
          line-height: 29px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .trailSession,
      .message,
      .saveToMyList {
        padding: 10px 80px;
        border: none;
        font-size: 15px;
        line-height: 23px;
        white-space: nowrap;
      }
      .trailSession {
        background-color: $primary__color;
        color: $white__color;
      }
      .message {
        border: 1px solid $primary__color;
        background: none;
        color: $primary__color;
      }
      .saveToMyList {
        border: 1px solid $primary__color;
        background: none;
        color: $primary__color;
        margin-bottom: 10px;
      }

      .bookedLessons,
      .response {
        display: flex;
        gap: 10px;

        p {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }

    .popularity {
      background-color: $white__color;
      display: flex;
      align-items: flex-start;
      gap: 10px;
      padding: 20px 20px;

      .text__Box {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .CoachProfile {
    flex-direction: column;

    .main {
      width: 100%;
    }

    .right__sidebar {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .CoachProfile {
    .main {
      .mini-navbar {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 525px) {
  .CoachProfile {
    .main {
      .SummaryBox {
        flex-direction: column;
      }
    }
  }
}
