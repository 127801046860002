@import '../Globals.scss';

.HowWeWork {
  display: block;
  margin: 8rem 0;
  background-color: $white__color;
  padding: 6rem 24rem 8rem;
  position: relative;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8rem;

    img {
      position: absolute;
      top: -5rem;
      width: 10rem;
      height: 10rem;
    }

    h1 {
      margin-bottom: 0;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: 6rem;

    .step {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      img {
        width: 68.4rem;
        height: 31rem;
        object-fit: contain;
        border-radius: $border__radius;
        margin-left: 1rem;
      }

      .left {
        display: flex;
        gap: 3rem;
        flex-direction: row;
        align-items: center;

        .text__box {
          max-width: 60rem;
        }

        .number {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $primary__color;
          min-width: 6.4rem;
          height: 6.4rem;
          border-radius: 50%;
          font-size: 4rem;
          font-family: Roboto;
          font-weight: 400;
          color: $white__color;
        }
      }
    }
  }
}

@media (max-width: 1800px) {
  .HowWeWork {
    .steps {
      .step {
        img {
          width: 48.4rem;
          height: 31rem;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .HowWeWork {
    padding: 6rem 8rem 8rem;

    .steps {
      .step {
        img {
          width: 48.4rem;
          height: 31rem;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .HowWeWork {
    margin: 0;
    padding: 6rem 2.4rem 8rem;

    .steps {
      .step {
        img {
          width: 22rem;
          height: auto;
        }

        .left {
          display: flex;
          gap: 2rem;
          flex-direction: row;
          align-items: center;

          .number {
            min-width: 3.2rem;
            height: 3.2rem;
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .HowWeWork {
    margin: 0;
    padding: 6rem 2.4rem 8rem;

    .steps {
      .step {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        img {
          width: 22rem;
          height: auto;
        }

        .left {
          display: flex;
          gap: 2rem;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .text__box {
            text-align: center;
          }

          .number {
            min-width: 3.2rem;
            height: 3.2rem;
            font-size: 2rem;
          }
        }
      }
    }
  }
}
