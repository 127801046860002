@import '../Globals.scss';
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Montserrat:wght@700&display=swap');

.QuoteSection {
  padding: 4rem 24rem 0rem;
  display: flex;
  gap: 8rem;
  align-items: center;
  background-color: $primary__color;

  > img {
    width: 70rem;
    height: auto;
  }

  .right {
    display: flex;
    flex-direction: column;
    max-width: 82rem;

    h1 {
      color: $white__color;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 4.8rem;
      line-height: 5.6rem;
    }

    p {
      color: $white__color;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;
      max-width: 45rem;
      margin-top: -2rem;
      margin-bottom: 4rem;
    }

    img {
      height: 6rem;
      width: auto;
    }

    img:nth-child(1) {
      margin-left: -6rem;
      margin-bottom: -3rem;
      align-self: flex-start;
    }
    img:nth-child(3) {
      margin-top: -4rem;
      align-self: flex-end;
    }

    button {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: center;
      max-width: 30%;
      background: $secondary__color;
      background: radial-gradient(
        circle at center,
        #fff 0,
        #fff 30%,
        $secondary__color 100%
      );
      border: none;
      border-radius: 2rem;
      padding: 2rem 2rem;
      color: $primary__color;
    }
  }
}

@media (max-width: 1800px) {
  .QuoteSection {
    padding: 4rem 18rem 0rem;
    > img {
      width: 62rem;
    }

    // .right {
    //   img:nth-child(3) {
    //     margin-top: -6rem;
    //     margin-right: 4rem;
    //     margin-bottom: 3.2rem;
    //     align-self: flex-end;
    //   }
    // }
  }
}

@media (max-width: 1500px) {
  .QuoteSection {
    padding: 4rem 8rem 0rem;
    > img {
      width: 44rem;
    }

    .right {
      h1 {
        font-size: 3.4rem;
        line-height: 4.4rem;
      }

      p {
        font-size: 2rem;
      }

      button {
        margin-top: -1rem;
        font-size: 2.4rem;
        line-height: 2.8rem;
      }

      img:nth-child(3) {
        margin-top: -2rem;
        margin-right: 6rem;
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 1200px) {
  .QuoteSection {
    padding: 4rem 8rem 4rem;
    > img {
      width: 32rem;
    }

    .right {
      h1 {
        font-size: 2.4rem;
        line-height: 4.4rem;
      }

      p {
        font-size: 1.4rem;
        margin-top: 1rem;
        margin-bottom: 4rem;
      }

      button {
        padding: 1rem;
        font-size: 2rem;
      }

      img {
        height: 4rem;
        width: auto;
      }

      img:nth-child(1) {
        margin-left: -4.2rem;
        margin-bottom: -2rem;
        align-self: flex-start;
      }
      img:nth-child(3) {
        margin-top: -3rem;
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 900px) {
  .QuoteSection {
    padding: 2rem 4rem 2rem;
    > img {
      width: 22rem;
      margin-bottom: -5rem;
    }

    .right {
      h1 {
        font-size: 2rem;
        line-height: 2.2rem;
      }

      p {
        font-size: 1.4rem;
        margin-top: 1rem;
        margin-bottom: 4rem;
        line-height: 2.2rem;
      }

      button {
        padding: 0.4rem 1rem;
        font-size: 1.8rem;
      }

      img {
        height: 4rem;
        width: auto;
      }

      img:nth-child(1) {
        margin-left: -4.2rem;
        margin-bottom: -2rem;
        align-self: flex-start;
      }
      img:nth-child(3) {
        margin-top: -2rem;
        margin-right: 0rem;
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 900px) {
  .QuoteSection {
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 2rem;
    > img {
      width: 22rem;
      margin-bottom: -3rem;
    }

    .right {
      align-items: center;
      h1 {
        text-align: center;
        font-size: 2rem;
        line-height: 2.2rem;
      }

      p {
        text-align: center;

        font-size: 1.4rem;
        margin-top: 1rem;
        margin-bottom: 4rem;
        line-height: 2.2rem;
      }

      button {
        padding: 0.4rem 1rem;
        font-size: 1.8rem;
      }

      img {
        height: 4rem;
        width: auto;
      }

      img:nth-child(1) {
        margin-left: -0.7rem;
        margin-bottom: 0rem;
        align-self: flex-start;
      }
      img:nth-child(3) {
        margin-top: -2rem;
        margin-right: 0rem;
        align-self: flex-end;
      }
    }
  }
}
