@import "../Globals.scss";

.Blogs {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
      }

    .blogs__container {
        padding-right: 40px;
        padding-left: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: auto;
        margin-left: auto;

        .page__row {
            padding-top: 15px;
            padding-bottom: 15px;

            .page__title {
                color: $primary__color;
                margin-bottom: 10px;
                font-size: 40px;
                font-weight: 500;
            }
        }

        .blogs__row {
            display: grid;
            flex-direction: row;
            padding-top: 15px;
            grid-template-columns: 1fr 1fr 1fr;
            padding-bottom: 15px;
            gap: 30px;
        }

        @media only screen and (max-width: 992px) {
            .blogs__row {
                grid-template-columns: 1fr 1fr;
            }  
        }

        @media only screen and (max-width: 575px) {
            .blogs__row {
                grid-template-columns: 1fr;
            }
        }
    }
}