@import "../Globals.scss";

.Chat {
  // padding: 0 240px 80px; // not working

  .contact__name__info {
    display: flex;
    flex-direction: row;
    width: 100%;

    .localTime {
      margin-left: 10px;
    }

    .localTime,
    .lastSeen {
      font-size: 14px;
      span {
        color: $white__color;
        font-weight: 500;
        font-style: italic;
      }
    }
  }

  padding: 0px;
  display: flex;
  overflow-x: hidden;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
  }

  .chat-room-container {
    height: 100%;
    width: 76.25%;

    .chat-room {
      display: grid;
      grid-template: 8vh 1fr 8vh / 100%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }

    .choose {
      align-items: center;
      justify-content: center;
      font-size: 2em;
    }
  }
  @media screen and (max-width: 510px) {
    .chat-room-container {
      width: 100%;
    }
  }

  .main__chat {
    background-color: #f2f2f2;
    width: 80%;

    .headOf__mainChat {
      background-color: $primary__color;
      height: 70px;
      max-height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-inline-start: 10px;

      .profile__photo {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
      }

      .textbox {
        margin-inline-start: 15px;
        .contactName {
          color: $white__color;
          font-weight: bold;
          font-size: 22px;
        }
      }
    }

    .bodyOF__mainChat {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 1%;
      height: 70vh;
      overflow-y: scroll;

      .message__box__container {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;

        .profile__pic__content {
          display: flex;
          align-items: flex-end;

          .profile__photo {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }
        }
      }

      .message__box__container.reverse {
        flex-direction: row-reverse;
      }

      .message__box__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-end;

        .message__box {
          color: $white__color;
          padding: 8px 12px;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 8px;
          max-width: 90%;
          margin-top: 2px;
          margin-bottom: 2px;
        }

        .message__box.sent {
          background-color: $primary__color;
          align-self: flex-end;
        }

        .message__box.received {
          color: $primary__color;
          background-color: $light__primary__color;
          align-self: flex-start;
        }
      }
    }

    .footerOf__mainChat {
      width: 100%;

      .form-group {
        display: flex;
        flex-direction: row;

        .new__message__input {
          width: 90%;
          height: 25px;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 10px;
          border: 1px solid #f1f1f1;
          border-radius: 4px;
          outline: none;
          font-size: 16px;
          transition: 0.5s;
        }

        .new__message__input:focus {
          box-shadow: $secondary__color 0px 2px 8px;
        }

        .btn-primary {
          margin-inline-start: 2px;
          margin-inline-end: 2px;
          width: 10%;
          text-align: center;
          background-color: $primary__color;
          color: $white__color;
          border: 1px solid $primary__color;
          border-radius: 4px;
          transition: 0.4s;
          font-size: 16px;
        }

        .btn-primary:hover {
          cursor: pointer;
          box-shadow: $secondary__color 0px 2px 8px;
          background-color: $secondary__color;
          color: $black__color;
          border: 1px solid $secondary__color;
        }
      }
    }
  }
}
