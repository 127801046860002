@import '../Globals.scss';

.CoachingsOverview {
  background-color: $light__primary__color;
  position: relative;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }

  .CO__container {
    padding: 2rem 24rem 8rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: $white__color;
    gap: 3rem;
    column-gap: 20rem;
    padding: 4rem 16rem;
    z-index: 1;
    // margin-top: -20rem;

    .catagory {
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;

      .catagory__icon__wrapper {
        img {
          width: 4rem;
          height: auto;
          object-fit: contain;
        }
      }

      .catagory__Text__box {
        .catagory__title {
          font-size: 2rem;
          font-weight: 500;
        }
        .catagory__numOfCoaches {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media (max-width: 1800px) {
  // nothing changed
}

@media (max-width: 1500px) {
  .CoachingsOverview {
    .CO__container {
      padding: 4rem 8rem 8rem;
      gap: 1.2rem;

      .catagory {
        .catagory__icon__wrapper {
          img {
            width: 3.2rem;
            height: auto;
            object-fit: contain;
          }
        }

        .catagory__Text__box {
          .catagory__title {
            font-size: 1.6rem;
            font-weight: 500;
          }
          .catagory__numOfCoaches {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .CoachingsOverview {
    .CO__container {
      padding: 2rem 2rem 2rem;

      .catagory {
        gap: 1.2rem;
        .catagory__icon__wrapper {
          img {
            width: 3.2rem;
            height: auto;
            object-fit: contain;
          }
        }

        .catagory__Text__box {
          .catagory__title {
            font-size: 1.6rem;
            font-weight: 500;
          }
          .catagory__numOfCoaches {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .CoachingsOverview {
    .CO__container {
      padding: 2rem 2rem 2rem;

      .catagory {
        gap: 1.2rem;
        .catagory__icon__wrapper {
          img {
            width: 3.2rem;
            height: auto;
            object-fit: contain;
          }
        }

        .catagory__Text__box {
          .catagory__title {
            font-size: 1.6rem;
            font-weight: 500;
          }
          .catagory__numOfCoaches {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .CoachingsOverview {
    .CO__container {
      padding: 2rem;

      .catagory {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .catagory__icon__wrapper {
          img {
            width: 2.4rem;
            height: auto;
            object-fit: contain;
          }
        }

        .catagory__Text__box {
          .catagory__title {
            text-align: center;
            font-size: 1.2rem;
            font-weight: 500;
          }
          .catagory__numOfCoaches {
            text-align: center;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
