@import "../Globals.scss";

.HomeBody {
  padding: 0 240px 80px;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }

  .Ideas {
    display: flex;
    gap: 40px;

    .Idea {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .numberOf__results {
    margin-top: 20px;
  }

  .gig__collection {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 40px;
    .gig {
      min-height: 380px;
      min-width: 320px;
      background-color: $white__color;
      border: 0.2px solid $gray__secondary__color;

      .header__box {
        overflow: hidden;
        background-image: url("../../assets/services/woman.jpg");
        background-position: center;
        background-size: cover;
        min-height: 190px;
        min-width: 318px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        img {
          width: 30px;
          height: auto;
        }
      }
      .middle__box {
        padding: 10px 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-top: 1px solid $light__gray__color;

        .userInfo {
          display: flex;
          gap: 10px;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
          .textBox {
            font-size: 14px;
            .fullName {
              font-weight: bold;
            }
            .rank {
              color: $light__gray__color;
            }
          }
        }

        .catagory {
          display: flex;
          margin: 8px 0;
        }
        .stars {
          display: flex;
          align-items: center;
          p {
            margin-left: 4px;
            color: #f7b24a;
            font-size: 14px;
            font-weight: bold;
          }
          .gray {
            color: $light__gray__color;
            font-weight: 400;
            margin-left: 2px;
          }
          img {
            width: 14px;
          }
        }
      }

      .footer__box {
        border-top: 0.3px solid $light__gray__color;
        padding: 10px 20px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .images_wrapper {
          display: flex;
          gap: 5px;
          img {
            height: 20px;
            width: auto;
            fill: $light__gray__color;
          }
        }
        .textBox {
          display: flex;
          align-items: center;
          p {
            color: $darker_gray__color;
            font-size: 10px;
            // text-transform: uppercase;
          }
          .price {
            margin-left: 4px;
            color: $darker_gray__color;
            font-weight: 400;
            font-size: 15px;
          }
        }
      }
    }
  }
}
