@import '../Globals.scss';
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Montserrat:wght@600&display=swap');

.HeroSection {
  padding: 4rem 24rem 8rem;
  background: rgb(51, 51, 51);
  background: linear-gradient(
    90deg,
    rgba(51, 51, 51, 1) 0%,
    rgba(33, 99, 165, 1) 29%,
    rgba(33, 165, 152, 1) 100%
  );
  // height: 100vh !important;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: -1;
  background-size: 200% 200%;
  animation: Animation 5s ease infinite;

  @keyframes Animation {
    0% {
      background-position: 10% 0%;
    }
    50% {
      background-position: 91% 100%;
    }
    100% {
      background-position: 10% 0%;
    }
  }

  .relativeBox {
    position: relative;
  }

  .relativeBox img {
    width: 55rem;
    height: auto;
    position: absolute;
    bottom: -32rem;
    right: 0rem;
  }

  .hero__heading {
    color: $white__color;
    font-size: 4rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    line-height: 4.7rem;

    .Cookie {
      font-family: 'Cookie', cursive;
      font-size: 6rem;
    }
  }

  .search__wrapper {
    display: flex;
    align-items: center;
    .search__btn {
      padding: 1.4rem 3rem;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.8rem;
      background-color: $light__primary__color;
      background: radial-gradient(
        circle at center,
        $light__primary__color 0%,
        $secondary__color 100%
      );
      border: none;
      border-top: 1px solid $light__primary__color;
      color: $white__color;
    }

    .search__input {
      padding: 1.8rem 3rem;
      font-size: 1.4rem;
      min-width: 25vw;
      border: none;
      outline: none;
    }

    .search__input:focus {
      box-shadow: rgba(33, 99, 165, 0.3) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  }
}

// $HugeScreens: 1800px;
// $desktop: 1500px;
// $laptop: 1200px;
// $tablet: 900px;
// $phone: 460px;

@media (max-width: 1800px) {
  .HeroSection {
    padding: 4rem 18rem 8rem;
    // min-height: 64rem;
    .relativeBox img {
      width: 52rem;
      bottom: -12rem;
      right: 0rem;
    }
  }
}

@media (max-width: 1500px) {
  .HeroSection {
    padding: 4rem 8rem 8rem;
    min-height: 68vh;
    .relativeBox img {
      width: 38rem;
      bottom: -14rem;
      right: 4rem;
    }

    .hero__heading {
      font-size: 3.6rem;
      line-height: 4.7rem;

      .Cookie {
        font-size: 5.2rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .HeroSection {
    padding: 4rem 8rem 8rem;
    .relativeBox img {
      width: 38rem;
      bottom: -14rem;
      right: 4rem;
    }

    .hero__heading {
      font-size: 3.6rem;
      line-height: 4.7rem;

      .Cookie {
        font-size: 5.2rem;
      }
    }
  }
}

@media (max-width: 900px) {
  .HeroSection {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .relativeBox img {
      display: none;
    }

    .hero__heading {
      font-size: 3.6rem;
      line-height: 4.7rem;

      .Cookie {
        font-size: 5.2rem;
      }
    }
  }
}
