@import "../../Globals.scss";

.FAQ {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;

  .faq-row-wrapper {
    background: none;
    .faq-title {
      padding: 10px 0;
      margin-bottom: 10px;
      color: $gray__color;
    }

    .faq-body {
      .faq-row {
        padding: 10px 0;
        .row-title {
        }

        .row-content {
          .row-content-text {
            color: $gray__color;
            line-height: 24px;
          }
        }
      }
    }
  }
}
