@import "../Globals.scss";

.blog__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: $border__radius;
    background-color: $white__color;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: $transition;

    .card__header {
        .card__image {
            width: 100%;
            height: 220px;
            border-radius: $border__radius;
        }
    }

    .card__body {
        padding: 10px;
        
        .card__title {
            margin-bottom: 5px;
            color: $black__color;

            h2 {
                font-size: 16px;
                font-weight: bold;
            }
        }

        .card__paragraph {
            color: $black__color;
        }
    }

    .card__footer {
        padding: 10px;
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        color: $light__gray__color !important;
    }
}

.blog__card:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
}