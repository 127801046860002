//  ============================
// Global Variables
//  ============================

$black__color: #333;
$black__secondary__color: #32393f;
$white__color: #ffffff;
$darker_gray__color: rgb(85, 85, 85);
$gray__color: rgba(0, 0, 0, 0.5);
$gray__secondary__color: #6f757b;
$gray__Description_color: #62646a;
$light__gray__color: #b5b6ba;
$primary__color: #2163a5;
$light__primary__color: #b1d8ff;
$very__light__blue: #eef5fc;
$secondary__color: #84dfff;
$green__color: #7ce577;
$star__color: #ffaa46;
$green__secondary__color: #eef7e9;
$green__darker__color: #50bf16;
$border__radius: 4px;
$transition: 0.4s;

//  ============================
// Standard Styles & Nutralisation
//  ============================

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.75rem;
  font-weight: 400;
}

p {
  font-size: 1.6rem;
  font-weight: 400;
}

//  ============================
// Wesbite Font
//  ============================

html {
  font-size: 62.5%;
}

@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Montserrat:wght@500;600&family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  font-family: 'Roboto', fell, Georgia, Cambria, 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: normal;
}

//  ============================
// Gloabal Classes
//  ============================

.hidden {
  display: none;
}

.text-primary {
  color: $primary__color !important;
}

.bg-primary {
  background-color: $primary__color !important;
}

.blueBtn {
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: center;
  background: $primary__color;
  border: none;
  border-radius: 2rem;
  padding: 2rem 2rem;
  color: $white__color;
}
