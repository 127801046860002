@import '../Globals.scss';

// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary__color;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1b2771;
}

.navIconWrapper {
  display: none;
  background-color: $white__color;
  border-radius: 50%;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2rem;
  right: 4rem;
  z-index: 999;

  .navIcon {
    display: none;
    width: 2rem;
    height: 2rem;
    color: $primary__color;
  }
}

.Navbar.scrolled {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding-block: 5px;
}

.Navbar {
  display: flex;
  gap: 18rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 18rem;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: 0.2s;
  background-color: $primary__color;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    color: $white__color;
  }

  .closeIcon {
    display: none;
    background-color: $white__color;
    position: absolute;
    top: 2rem;
    right: 4rem;
    z-index: 999;
  }

  .logo__wrapper {
    margin-left: -0.2rem;
  }

  .Searchbar {
    flex: 1;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border: 0.1rem solid $light__primary__color;
    border-radius: 2rem;
    overflow: hidden;
    padding: 0 0 0 1rem;

    .iconWrapper {
      img {
        color: $white__color;
      }
    }

    input {
      flex: 1;
      background: none;
      border: none;
      padding: 0.8rem;
      font-size: 1.2rem;
      font-weight: 600;
    }
    input:focus {
      color: $white__color;
      outline: none;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white__color;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $white__color;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $white__color;
    }

    button {
      padding: 1rem 1.5rem;
      border: none;
      background-color: $white__color;
      color: $primary__color;
    }
  }

  .nav__lists {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: 1.6rem;
    text-transform: uppercase;

    li {
      list-style: none;
      font-weight: 500;
      a {
        text-decoration: none;
        font-weight: 500;
        position: relative;
        color: $white__color;
      }

      a:hover:before {
        content: '';
        position: absolute;
        bottom: -0.7rem;
        left: 0;
        width: 100%;
        height: 0.2rem;
        background-color: $white__color;
      }

      .loginBtn,
      .registerBtn {
        padding: 1rem 1.5rem;
        font-weight: 500;
      }

      .loginBtn {
        margin-left: 0.5rem;
        background: none;
        border: 0.1rem solid $white__color;
        border-radius: 2rem;
        color: $white__color;
      }
      .registerBtn {
        background-color: $white__color;
        border: none;
        border-radius: 2rem;
        color: $primary__color;
      }
    }
  }
}

@media (max-width: 1800px) {
  .Navbar {
    gap: 12rem;
  }
}

@media (max-width: 1500px) {
  .Navbar {
    gap: 8rem;
    padding: 1rem 8rem;
  }
}

@media (max-width: 1200px) {
  .Navbar {
    gap: 2rem;
    padding: 1rem 2rem;

    .logo__wrapper {
      margin-right: -1.6rem;
    }

    .nav__lists {
      gap: 0.2rem;
      font-size: 1.6rem;

      li {
        margin-left: 0.4rem;
      }
    }
  }
}

@media (max-width: 900px) {
  .navIconWrapper {
    display: flex;

    .navIcon {
      display: block;
    }
  }

  .Navbar {
    display: none;
    .closeIcon {
      display: block;
    }
  }
}
