@import "../Globals.scss";

.signUpForm {
  margin: 0 auto;
  max-width: 25vw;

  .closeBtn {
    position: absolute;
    top: 8px;
    right: 10px;
    color: $black__color;
    font-weight: bold;

    button {
      background: none;
      border: none;
      font-size: 25px;
      transition: $transition;
      transform: scaleY(.7);
    }

    button:hover {
      color: $primary__color;
    }
  }

  .text-box {
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 46px;
      margin: 8%;
      margin-bottom: 10px;
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
    }
  }

  .inputs {
    width: 100%;
    input {
      width: 100%;
      display: block;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, .7);
      border-radius: 12px;
      height: 50px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      margin: 5% auto;
      outline: none;
      padding: 5px 5px 5px 20px;
      transition: .4s;
    }

    input:first-child {
      margin-bottom: 20px;
    }

    input:focus {
      box-shadow: rgba(33, 99, 165, 0.3) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  }
  .error {
    color: red;
    margin: -15px 0px 15px 5px;
  }
  .error:nth-child(4) {
    margin: 5px 0px 0px 5px;
  }

  .signUpBtn {
    width: 100%;
    padding-inline: 12px;
    padding-block: 8px;
    border-radius: 8px;
    font-size: 16px;
    border: 0;
    background-color: $primary__color;
    color: $white__color;
    transition: $transition;
  }

  .signUpBtn:hover {
    box-shadow: rgba(33, 99, 165, 0.3) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .breakline {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .breakline span {
    display: block;
    min-width: 180px;
    flex: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: $gray__color;
  }

  .breakline > div {
    display: block;
    background-color: #c4c4c4;
    width: 100%;
    height: 1px;
  }

  .btns {
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: space-around;

    button {
      background-color: $white__color;
      padding: 10px 15%;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;

      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }

  .gray__text {
    margin-top: 8px;
    color: $gray__color;
  }
}
