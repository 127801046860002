@import "../Globals.scss";

.Add__Blog {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }

    .page__row {
        padding-top: 15px;
        padding-bottom: 15px;

        .page__title {
            color: $primary__color;
            margin-bottom: 10px;
            font-size: 40px;
            font-weight: 500;
        }

        .form__group {
            width: 100%;
            margin-bottom: 12px;
            display: flex;
            flex-direction: column;

            .form__label {
                cursor: pointer;
                font-weight: bold;
                font-size: 16px;
            }

            .form__control {
                width: 100%;
                outline: 0;
                padding: 5px;
                font-size: 14px;
                border: 0;
                border-radius: 8px;
                transition: $transition;
            }

            .form__control:focus {
                box-shadow: rgba(33, 99, 165, 0.3) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            }

            .required {
                font-size: 14px;
                font-weight: bold;
                color: #BB2124;
            }

            .success {
                font-size: 14px;
                font-weight: bold;
                color: $green__darker__color;
            }

            .error {
                font-size: 14px;
                font-weight: bold;
                color: #d9534f;
            }

            .btn__primary {
                width: fit-content;
                padding-inline: 12px;
                padding-block: 8px;
                border-radius: 8px;
                font-size: 16px;
                border: 0;
                background-color: $primary__color;
                color: $white__color;
                transition: $transition;
            }

            .btn__primary:hover {
                box-shadow: rgba(33, 99, 165, 0.3) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            }
        }
    }
}