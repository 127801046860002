@import '../Globals.scss';

.left__sidebar {
  width: 20%;
  background-color: $white__color;

  .headOf__sidebar {
    max-height: 70px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    background-color: $primary__color;
    color: $white__color;
    font-weight: bold;
    font-size: 40px;

    .profile__photo {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }

    .right__options {
      display: flex;
      gap: 15px;

      .search__for__users {
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        outline: none;
        transition: 0.4s;
      }

      .search__for__users:focus {
        box-shadow: $secondary__color 0px 2px 8px;
      }
    }
  }
}
