@import '../Globals.scss';

.Footer {
  .nav {
    padding: 4rem 24rem 8rem;
    background-color: $white__color;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    row-gap: 60px;
    overflow: hidden;

    .links-box {
      .links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        a {
          font-size: 1.8rem;
          font-weight: 500;
          color: $gray__color;
          text-decoration: none;
          margin-bottom: 4px;
          transition: 0.4s;
        }

        a:hover {
          color: $primary__color;
        }
      }

      .social-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        a {
          font-size: 14px;
          color: $black__color;
          text-decoration: none;
          margin-inline-end: 4px;
          transition: 0.4s;
        }

        a:hover {
          color: $primary__color;
        }
      }

      h2 {
        color: $primary__color;
        margin-bottom: 10px;
        font-size: 2.4rem;
        margin-bottom: 1.2rem;
        // text-transform: uppercase;
      }
    }
  }

  .copyright {
    background-color: $black__secondary__color;
    color: $white__color;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 1rem 0;

    a {
      font-size: 14px;
      color: $white__color;
      text-decoration: none;
      transition: 0.4s;
    }

    a:hover {
      color: $secondary__color;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .Footer {
    .nav {
      padding: 4rem 6rem 8rem;
    }
  }
}

@media only screen and (max-width: 992px) {
  .Footer {
    .nav {
      padding: 2rem 8rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (max-width: 575px) {
  .Footer {
    .nav {
      padding: 1rem 4rem;
      grid-template-columns: 1fr;
    }
  }
}
