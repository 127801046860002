@import "../Globals.scss";

.SignIn {
  margin: 0 auto;
  max-width: 25vw;

  .closeBtn {
    position: absolute;
    top: 8px;
    right: 10px;
    color: $black__color;
    font-weight: bold;

    button {
      background: none;
      border: none;
      font-size: 25px;
      transition: $transition;
      transform: scaleY(.7);
    }

    button:hover {
      color: $primary__color;
    }
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
  }

  h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 46px;
    margin: 15%;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    display: block;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, .7);
    border-radius: 12px;
    height: 50px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin: 5% auto;
    outline: none;
    padding: 5px 5px 5px 20px;
    transition: .4s;
  }

  input:focus {
    box-shadow: rgba(33, 99, 165, 0.3) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .submit {
    width: 100%;
    padding-inline: 12px;
    padding-block: 8px;
    border-radius: 8px;
    font-size: 16px;
    border: 0;
    background-color: $primary__color;
    color: $white__color;
    transition: $transition;
  }

  .submit:hover {
    box-shadow: rgba(33, 99, 165, 0.3) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
}
