@import 'Globals.scss';

.Coaches {
  .Hero {
    padding: 60px 240px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../assets/home/becomeACoach/boyStudent.png');
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: -1;

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    .CoachesForm {
      background-color: $white__color;
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 30px;
      max-width: 25vw;
      box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.08);

      .text-box {
        h1 {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 46px;
          margin-bottom: 0px;
        }
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
        }
      }

      .inputs {
        width: 100%;
        input {
          display: block;
          background: #ffffff;
          border: 1px solid #c4c4c4;
          box-sizing: border-box;
          height: 50px;
          width: 100%;
          padding: 5px 20px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
        }
        input:first-child {
          margin-bottom: 20px;
        }
      }
      .error {
        color: red;
        margin: -15px 0px 15px 5px;
      }
      .error:nth-child(4) {
        margin: 5px 0px 0px 5px;
      }

      .CoachesBtn {
        margin-top: 10px;
        background-color: $primary__color;
        color: white;
        padding: 10px;
        border: none;
        width: 100%;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
      }

      .breakline {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .breakline span {
        display: block;
        min-width: 180px;
        flex: 100%;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: $gray__color;
      }

      .breakline > div {
        display: block;
        background-color: #c4c4c4;
        width: 100%;
        height: 1px;
      }

      .btns {
        width: 100%;
        margin-top: 8px;
        display: flex;
        justify-content: space-around;

        button {
          background-color: $white__color;
          padding: 10px 15%;
          border: 1px solid #c4c4c4;
          box-sizing: border-box;

          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
          text-align: center;
        }
      }

      .gray__text {
        margin-top: 8px;
        color: $gray__color;
      }
    }
  }
  .CoacheBenefits {
    padding: 80px 240px;
    display: flex;

    .benefit__column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 120px;

      .text-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .circle {
      background-color: $primary__color;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
  .TeachStudents {
    padding: 80px 240px;
    display: flex;
    flex-direction: column;

    background-color: $secondary__color;

    ul {
      list-style: none;

      li {
        margin-bottom: 20px;
        margin-left: -37px;
      }
    }

    button {
      max-width: 240px;
      padding: 20px;
      background-color: $primary__color;
      color: $white__color;
      border: none;
    }
  }

  .FAQ {
    padding: 80px 240px 120px;

    h1 {
      text-align: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      margin-bottom: 60px;
    }

    .questions {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .question {
        display: flex;
        gap: 20px;
        align-items: center;

        .circle {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          background-color: $secondary__color;
        }

        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 37px;
        }
      }
    }
  }

  .GetPaid {
    padding: 300px 240px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../assets/home/becomeACoach/girlCoach.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      color: $white__color;
    }

    h1 {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 40px;
    }

    p {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 60px;
      max-width: 750px;
    }

    button {
      padding: 20px;
      background-color: $primary__color;
      border: 0;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
  }
}
